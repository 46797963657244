export default class Marquee {
    constructor(element)
    {
        this.element = element
    }

    init()
    {
        console.log(jQuery.fn);
        const scrollDuration = $(this.element).data('duration')
        const marqueeElement = $(this.element).find('.marquee')
        // console.log('marqueeElement',);

        jQuery(marqueeElement).marquee({
            duration: scrollDuration * 1000,
            duplicated: true,
            delayBeforeStart: 0
        })
    }
}
