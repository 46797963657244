import Carousel from "../util/carousel";

const TESTIMONIALS_OPTIONS = {
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 30,
    grabCursor: true,
    navigation: {
        nextEl: '.testimonials-slider .swiper-next',
        prevEl: '.testimonials-slider .swiper-prev',
    },

    breakpoints: {
        768: {
            spaceBetween: 60
        },
    }
}

export default class TestimonialsSlider {
    constructor (element) {
        this.element = element
        this.options = TESTIMONIALS_OPTIONS
    }

    start () {
        this.carousel = new Carousel(this.element, this.options)
        this.carousel.init()
    }
}
