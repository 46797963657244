window.$ = jQuery;

import '../styles/app.scss';
import 'swiper/css/bundle';

/*import 'simplebar/dist/simplebar.css';*/


import Router from './util/Router';
import common from './routes/common';
import aboutUs from './routes/about';

// window.ResizeObserver = ResizeObserver;

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
});

// Load Events
$(document).ready(() => routes.loadEvents());
