export default class OngletsHorizontalOverride {
    constructor(element) {
        this.element = element
    }

    init() {
        const swiperWrapper = this.element.querySelector('.fd-tabs-buttons .swiper-wrapper');
        const swiperWrapperWidth = swiperWrapper.offsetWidth;

        this.checkViewportWidth(swiperWrapper, swiperWrapperWidth)

        window.addEventListener('resize', () => {
            this.checkViewportWidth(swiperWrapper, swiperWrapperWidth)
        });
    }

    checkViewportWidth(elt, eltWidth) {
        const tabs = elt.closest('.fd-tabs-buttons');

        if (window.innerWidth <= eltWidth) {
            tabs.classList.add('activate-slide');
        } else {
            tabs.classList.remove('activate-slide');
        }
    }
}
