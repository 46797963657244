const SWIPER_CONFIG = {
    spaceBetween: 0,
    pagination: false,
    navigation: false,
    loop: false
}

export default class Carousel {
    constructor (element, options) {
        this.options = options
        this.swiper = element.querySelector('.swiper')
    }

    init () {
        const configurations = {
            ...SWIPER_CONFIG,
            ...this.options
        }


        try {
            new Swiper(this.swiper, configurations);
            console.log('swiper triggered')
        }
        catch (e) {
            console.log('no swiper available')
        }
    }
}
