export default class TabsWithIcons {
    constructor(element) {
        this.element = element
    }

    init() {
        $(this.element).on("click", function (e) {
            e.preventDefault();
            const secteur_id = jQuery(this).data('target');
            $('.ct_secteurs .secteurs a').removeClass('actif')
            $(this).addClass('actif')
            $('.un_secteur').css('display', 'none');
            $(secteur_id).fadeIn();
        })
    }
}
