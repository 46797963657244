export default class Menu {
    /**
     * Constructor
     */
    constructor() {
        this.$btnHamburger = $('.hamburger');
        this.$menuRootItems = $('.menu .menu-item');
        this.$submenuPanel = $('.submenu-panel');
        this.$menuContainer = $('#header .conteneur');
        this.$currentTarget = null;
        this.menuTimeout = setTimeout('', 0);
        this.menuContainerTimeout = setTimeout('', 0);
    }

    init () {
        console.log('menu.init');

        this.$btnHamburger.on('click', (e) => {
            jQuery(e.currentTarget).toggleClass('is-active');
            jQuery("#hmobile").toggleClass("open");
            jQuery("html").toggleClass("menu-open");

            if ($('body').hasClass('submenu-open')) {
                this.closeMenu();
            }
        });

        const updateMenuBehavior = () => {
            if (window.matchMedia("(min-width: 1440px)").matches) {
                this.$menuRootItems.off('click').hover((e) => {
                    clearTimeout(this.menuTimeout);
                    if (this.$currentTarget !== e.currentTarget) {
                        this.selectMenu(e.currentTarget);
                        this.$currentTarget = e.currentTarget;
                    }
                }, () => {

                });

                this.$submenuPanel.on('mouseenter', () => {
                    clearTimeout(this.menuTimeout);
                    clearTimeout(this.menuContainerTimeout);
                }).on('mouseleave', () => {
                    this.menuTimeout = setTimeout(() => {
                        this.closeMenu();
                    }, 200);
                });
                
                this.$menuContainer.on('mouseenter', () => {
                    clearTimeout(this.menuContainerTimeout);
                }).on('mouseleave', () => {
                    this.menuContainerTimeout = setTimeout(() => {
                        this.closeMenu();
                    }, 200);
                });

            } else {

                this.$menuRootItems.off('hover').off('mouseenter');
                this.$menuRootItems.on('click', (e) => {
                    e.preventDefault();
                    if ($(e.currentTarget).hasClass('is-active')) {
                        this.closeMenu();
                    } else {
                        this.selectMenu(e.currentTarget);
                    }
                });
            }
        };

        updateMenuBehavior();

        $(window).on('resize', () => {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                updateMenuBehavior();
            }, 200);
        });

        $('.link-back').on('click', ()=> {
            this.closeMenu();
            return false;
        });
    }



    selectMenu ( _target ) {
        const $menuItem = $(_target);
        const $target = $menuItem.attr('href').indexOf('#') === 0 ? $('.submenu-panel' + $menuItem.attr('href')) : null;

        if ( $target && $target.length > 0 ) {
            $('body').addClass('submenu-open submenu-mobile-open');
            this.$submenuPanel.removeClass('active');
            this.$menuRootItems.removeClass('active');

            $target.addClass('active');
            $menuItem.addClass('active');
        } else {
            this.closeMenu();
        }
    }

    closeMenu () {
        $('body')
            .removeClass('submenu-open')
            .removeClass('submenu-mobile-open');

        this.$submenuPanel.removeClass('active');
        this.$menuRootItems.removeClass('active');

        this.$currentTarget = null;
    }
}
